.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 19px 0;
    margin: 0 20%;
}

.logo {
    width: 60px;
}

.logo img {
    border-radius: 25%;
    width: 100%;
}

.wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 46px;
}

.menu {
    font-weight: 600;
    color: #38424D;
    font-size: 16px;
    cursor: pointer;
}

.menuSelected {
    color: #ED7690;
}