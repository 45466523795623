.container {
    display: flex;
    align-items: center;
    background-color: #ed76900e;
    margin: 120px 0% 0 0%;
    padding: 120px 0;
}

.wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
    padding: 0 20%;
}

.text {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #38424D;
    gap: 12px;
}

.title {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
}

.subtitle {
    color: #747E88;
}

.image {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
}

.circle {
    z-index: -1;
    position: absolute;
    left: 50px;
    top: 50px;
    background-color: #ED7690;
    border-radius: 50%;
    width: 450px;
    height: 450px;
    border: 5px solid #fff;
    box-shadow: 0 0 0 5px #ED7690;
}

.image img {
    width: 250px;
}

.button {
    margin-top: 8px;
    width: 180px;
    padding: 12px 24px;
    background-color: #ED7690;
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}