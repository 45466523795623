.container {
    display: flex;
    align-items: center;
    margin-top: 120px;
    margin: 120px 20% 0 20%;
}

.wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 60px;
}

.text {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #38424D;
    gap: 12px;
}

.title {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
}

.subtitle {
    color: #747E88;
    text-align: center;
}

.card {
    width: 540px;
    display: flex;
    padding: 24px;
    gap: 18px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(154, 154, 154, 0.16);
    text-align: center;
    align-items: center;
    justify-content: center;
}

.cardInfos {
    display: flex;
    flex-direction: column;
    gap: 6px;
    text-align: left;
}

.cardTitle {
    font-weight: 600;
    font-size: 18px;
}

.cardSubTitle {
    font-size: 14px;
}

.cards {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
}

.icon {
    border-radius: 50%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon span {
    font-size: 28px !important;
    color: #FFFFFF;
}

.features {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
    width: 100%;
}

.image {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
}

.circle {
    z-index: -1;
    position: absolute;
    left: 45px;
    top: 80px;
    background-color: #5c0668;
    border-radius: 50%;
    width: 450px;
    height: 450px;
    border: 5px solid #fff;
    box-shadow: 0 0 0 5px #5c0668;
}

.image img {
    width: 300px;
}