.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 105px);
    margin: 0 20%;
}

.wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #38424D;
    gap: 32px;
}

.title {
    font-size: 42px;
    font-weight: 600;
    line-height: 48px;
}

.subtitle {
    color: #747E88;
}

.hightlight {
    color: #ED7690;
}

.image {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.image img {
    width: 320px;
}

.button {
    width: 180px;
    padding: 12px 24px;
    background-color: #ED7690;
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.circle {
    z-index: -1;
    position: absolute;
    left: 185px;
    top: 156px;
    background-color: #ED7690;
    width: 385px;
    height: 507px;
    border: 8px solid #fff;
    box-shadow: 0 0 0 2px #5c0668;
    rotate: -61deg;
}