.container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0;    
}

.wrapper {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 24px;
}

.button {
    cursor: pointer;
}

.picture {
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 24px;
    font-weight: 600;
    margin: 18px 0;
}

.content {
    width: 100%;
    flex: 1;
    border-radius: 8px;
    padding: 24px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
    background-color: white;
}

.topic {
    font-size: 18px;
    font-weight: 600;
}