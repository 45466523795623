.container {
    display: flex;
    align-items: center;
    margin-top: 120px;
    margin: 120px 20% 0 20%;
}

.wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 60px;
}

.text {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #38424D;
    gap: 12px;
}

.title {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
}

.subtitle {
    color: #747E88;
    text-align: center;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 18px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(154, 154, 154, 0.16);
    text-align: center;
}

.cardTitle {
    font-weight: 600;
    font-size: 18px;
}

.cardSubTitle {
    font-size: 14px;
}

.cards {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    border-radius: 50%;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon span {
    font-size: 48px !important;
    color: #FFFFFF;
}